import React, { createContext, useEffect, useState } from 'react'

export const AppThemeContext = createContext()
export function AppThemeProvider({ children }) {
    const [PrimaryColor, setPrimaryColor] = useState('#356E35')

    useEffect(() => {
        const usecolor = localStorage.getItem('UserTheme')
        if (usecolor) {
            setPrimaryColor(usecolor)
        } else {
            setPrimaryColor('#356E35')
        }
    }, [PrimaryColor])

    return (
        <AppThemeContext.Provider value={{ setPrimaryColor, PrimaryColor }}>
            {children}
        </AppThemeContext.Provider>
    )
}
